"use strict";
import * as bootstrap from "bootstrap";
import "../scss/custom.scss";
import formValidation from "./helpers/formValidation";
let level = 0;

const STATE = {};

const els = Object.entries({
  email: "email",
  phone: "phone",
  name: "name",
  updates: "updates",
  submitBtn: "submitBtn",
  playBtn: "playBtn",
  clickMainPage: "clickMainPage",
  consumerForm: "consumerForm",
  consumerDiv: "consumerDiv",
  bonus: "bonus",
  prize1: "prize1",
  prize2: "prize2",
  prize3: "prize3",
  prize4: "prize4",
}).reduce((elObj, entry) => {
  elObj[entry[0]] = document.getElementById(entry[1]);
  return elObj;
}, {});

const pages = Object.entries({
  page1: "page1",
  page2: "page2",
  page3: "page3",
}).reduce((elObj, entry) => {
  elObj[entry[0]] = document.getElementById(entry[1]);
  return elObj;
}, {});

document.addEventListener("DOMContentLoaded", function () {
  formValidation();
  showPage(1);
  els.clickMainPage.addEventListener("click", playBtnClick);
  // els.playBtn.addEventListener("click", playBtnClick);
  // els.submitBtn.addEventListener("click", submitBtnClick);

  els.consumerForm.addEventListener("submit", (event) => {
    event.preventDefault();
    if (!email.checkValidity()) {
      alert("Please enter a valid email address");
      console.log("invalid email");
      return;
    }
    const formData = {
      email: els.email.value,
      phone: els.phone.value,
      agree: true,
      name: els.name.value,
      updates: els.updates.checked,
      language,
    };
    fetch("/play/winner", {
      method: "POST",
      headers: {
        "CSRF-Token": _csrf, // <-- is the csrf token as a header
        "Content-Type": "application/json",
      },
      body: JSON.stringify(formData),
    })
      .then((response) => response.json())
      .then((response) => {
        if (response && response.err) {
          alert(response.err);
          showPage(3);
        } else {
          showPage(3);
        }
      })
      .catch((err) => {
        alert(
          "Something went wrong!!  Please email us directly @ info@promocafe.net so  we can fix it for you"
        );
      });
  });
});

// function submitBtnClick(event) {
//     event.preventDefault();
//     showPage(3);
// }

function playBtnClick(event) {
  event.preventDefault();

  fetch("/play/start", {
    method: "POST",
    headers: {
      "CSRF-Token": _csrf, // <-- is the csrf token as a header
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      // we are no longer collecting info Before random entry
      // email: email.value,
      // agree: agree.checked,
      language,
    }),
  })
    .then((response) => response.json())
    .then((response) => {
      if (response && response.err) {
        alert(response.err);
        // location.href = "https://www.sedanos.com/";
        location.reload();
      }
      const level = response.level;
      console.log(response);

      showPrize(level);
      // if (level == 4) {
      //   hide(els["consumerDiv"]);
      // } else {
      show(els["bonus"]);
      // }

      showPage(2);
    })
    .catch((err) => {
      console.log(err);
      // location.reload();
    });
}

function show(...els) {
  els.forEach((el) => el.classList.remove("hidden"));
}

function hide(...els) {
  els.forEach((el) => el.classList.add("hidden"));
}

function showPage(number) {
  Object.entries(pages).map(([key, el]) => {
    if (el.id === "page" + number) {
      show(el);
      if (number != 1) {
        el.querySelector(".sedanos-page-data").scrollIntoView({
          behavior: "smooth",
        });
      }
    } else {
      hide(el);
    }
  });
}
function showPrize(number) {
  let el = "prize" + number;
  console.log(el);
  show(els["prize" + number]);
}
